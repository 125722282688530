.image-box {
  position: relative;
  overflow: hidden;
  &:before {
    content: '';
    position: absolute;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: transparent;
    transform: translateY(100%);
    transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out;
  }
}

.is-dragging-image {
  &:before {
    background-color: #8ad2ff87;
    transform: translateY(0%);
  }
}

